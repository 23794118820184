import { TFunction } from "i18next";
import { AGGREGATE_TYPES, GENDER_TYPES } from "../../../../config/const";
import { AggregateTypeTextDef, GenderTypeTextDef } from "../../../../config/text-def";
import { ChartSeriesColumnOption } from "../../../../types";
import { BaseGenderTypeColumnChart, BaseGenderTypeColumnChartSeriesCode } from "../base-gender-type-column-chart";

export class EmploymentRatioAgeDistributionOfRegularEmployeeByGenderChart extends BaseGenderTypeColumnChart {
  protected _createSeriesDef(t: TFunction): Map<BaseGenderTypeColumnChartSeriesCode, ChartSeriesColumnOption> {
    const results = new Map<BaseGenderTypeColumnChartSeriesCode, ChartSeriesColumnOption>();

    AGGREGATE_TYPES.forEach((aggregateType) => {
      GENDER_TYPES.forEach((genderType, i) => {
        if (genderType === "all") {
          return;
        }
        const key = `${aggregateType}_${genderType}` as BaseGenderTypeColumnChartSeriesCode;
        results.set(key, {
          name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
            GenderTypeTextDef.get(genderType) as string
          )}`,
          color: super.getColor(aggregateType, i),
        });
      });
    });

    return results;
  }
}
