import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES, GRADE_TYPES, OUR_COMPANY_COLORS } from "../../../config/const";
import { AggregateTypeTextDef, GradeTypeTextDef } from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import { AggregateType, AggregateTypeRow, BaseData, ChartSeriesLineOption, GradeType } from "../../../types";
import { BaseLineChart } from "../base-line-chart";

export type BaseGradeTypeLineData = {
  gradeType: GradeType;
} & BaseData;

export type BaseGradeTypeLineChartSeriesCode = `${AggregateType}_${GradeType}`;

export class BaseGradeTypeLineChart extends BaseLineChart<BaseGradeTypeLineData> {
  getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<BaseGradeTypeLineData> | ChartQueryResult<BaseGradeTypeLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    return {
      ...super.getChartOptions(t, queryResult, displaySwitch, inBoard),
      series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDef(t)),
    };
  }

  protected _getSeriesCode(datum: BaseGradeTypeLineData): BaseGradeTypeLineChartSeriesCode {
    return `${datum.aggregateType}_${datum.gradeType}`;
  }

  protected _createSeriesDef(t: TFunction): Map<BaseGradeTypeLineChartSeriesCode, ChartSeriesLineOption> {
    const results = new Map<BaseGradeTypeLineChartSeriesCode, ChartSeriesLineOption>();
    AGGREGATE_TYPES.forEach((aggregateType) => {
      GRADE_TYPES.forEach((gradeType, i) => {
        const colorIndex = Math.floor(i * ((OUR_COMPANY_COLORS.length - 1) / (GRADE_TYPES.length - 1)));
        results.set(`${aggregateType}_${gradeType}`, {
          name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
            GradeTypeTextDef.get(gradeType) as string
          )}`,
          color: super.getColor(aggregateType, colorIndex),
          dashStyle: super.getDashStyle(aggregateType),
        });
      });
    });
    return results;
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult: ChartQueryResult<BaseGradeTypeLineData> | ChartQueryResult<BaseGradeTypeLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
      aggregateType,
      rows: GRADE_TYPES.map((gradeType) => {
        return {
          header: t(GradeTypeTextDef.get(gradeType) as string) as string,
          unit: queryResult.unit,
          values: super.getValuesByCondition(
            queryResult,
            (datum) => datum.aggregateType === aggregateType && datum.gradeType === gradeType
          ),
        };
      }),
    }));
  }
}
