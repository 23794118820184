import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogHeader } from "../../../components/dialog-header";
import { ChartTextDef, IndividualListTextDef } from "../../../config/text-def";
import { Chart } from "../../../types";
import { BusinessYearVo } from "../../../types/vo";
import { DashboardIndividualListGetRequest } from "../dashboard-api";
import { DashboardIndividualListTable } from "./dashboard-individual-list-table";

type DashboardIndividualListDialogProps = {
  open: boolean;
  onClose: () => void;
  chart: Chart;
  years: BusinessYearVo[];
  legendItems: string[];
  individualListFilter: DashboardIndividualListGetRequest;
};

const INITIAL_LEGEND_ITEM = "all";
const INITIAL_COMPANY_TYPE = "original";
const INITIAL_COMPANY_IDS: string[] = [];

export const DashboardIndividualListDialog = ({
  open,
  onClose,
  chart,
  years,
  legendItems,
  individualListFilter,
}: DashboardIndividualListDialogProps) => {
  const { t } = useTranslation();
  const [currentFilter, setCurrentFilter] = useState<DashboardIndividualListGetRequest>({
    businessYearStartDate: individualListFilter.businessYearStartDate ?? years[0].startDate,
    companyType: individualListFilter.companyType ?? INITIAL_COMPANY_TYPE,
    companyIds: individualListFilter.companyIds ?? INITIAL_COMPANY_IDS,
    legendItem: individualListFilter.legendItem ?? INITIAL_LEGEND_ITEM,
  });
  const [selectedItem, setSelectedItem] = useState<string>(currentFilter.legendItem as string);
  const [selectedYearDate, setSelectedYearDate] = useState<string>(
    years.find((year) => year.startDate === individualListFilter.businessYearStartDate)?.startDate ?? years[0].startDate
  );

  const handleSubmit = (item: string, year: string) => {
    onSubmit(item, year);
  };
  const onSubmit = (item: string, yearStartDate: string) => {
    const selectedRequestParam: DashboardIndividualListGetRequest = {
      businessYearStartDate: yearStartDate,
      companyType: individualListFilter.companyType,
      companyIds: individualListFilter.companyIds,
      legendItem: item,
    };
    setCurrentFilter(selectedRequestParam);
  };

  const getItemKey = (item: string): string => {
    return chart + "_" + item;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogHeader onClose={onClose}>{t("refer.individual-list")}</DialogHeader>
      <DialogContent dividers>
        <Grid container mb={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{t(ChartTextDef.get(chart) as string)}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" spacing={2} mb={2}>
          <Grid item width={150}>
            <Typography mb={1}>{t("individual-list.display-year")}</Typography>
            <Select
              value={selectedYearDate}
              onChange={(e) => {
                setSelectedYearDate(e.target.value);
                handleSubmit(selectedItem, e.target.value);
              }}
              fullWidth
            >
              {years.map((year) => (
                <MenuItem key={year.startDate} value={year.startDate}>
                  {year.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Typography mb={1}>{t("individual-list.display-legend-item")}</Typography>
            <Select
              value={selectedItem}
              onChange={(e) => {
                setSelectedItem(e.target.value);
                handleSubmit(e.target.value, selectedYearDate);
              }}
            >
              {legendItems.map((item) => (
                <MenuItem key={getItemKey(item)} value={item}>
                  {t(IndividualListTextDef.get(getItemKey(item)) as string)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <DashboardIndividualListTable individualListFilter={currentFilter} chart={chart} />
      </DialogContent>
      <DialogActions>
        <Button color="normal" variant="contained" onClick={onClose}>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
