import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { DialogHeader } from "../../../components/dialog-header";
import { LoadingModal } from "../../../components/loading/loading-modal";
import { CHARACTER_LIMIT } from "../../../config/const";
import { dateUtil } from "../../../utils/date";
import "dayjs/locale/ja";

export type BusinessYearFormModel = {
  startDate: Date;
  endDate: Date;
  name: string;
};

type BusinessYearBaseFormProps = {
  edit?: boolean;
  title: string;
  buttonLabel: string;
  defaultValue: BusinessYearFormModel;
  open: boolean;
  loading: boolean;
  done: boolean;
  onSubmit: (value: BusinessYearFormModel) => void;
  onClose: () => void;
};

export const BusinessYearBaseForm = ({
  edit = false,
  title,
  buttonLabel,
  defaultValue,
  open,
  loading,
  done,
  onSubmit,
  onClose,
}: BusinessYearBaseFormProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<BusinessYearFormModel>({
    defaultValues: {
      startDate: defaultValue.startDate,
      endDate: defaultValue.endDate,
      name: defaultValue.name,
    },
    resolver: yupResolver(
      yup.object<yup.AnyObject, Record<keyof BusinessYearFormModel, yup.AnySchema>>({
        startDate: yup
          .date()
          .required(t("validation.required") as string)
          .test("on-or-before-28th", t("validation.start-date") as string, (value) => dayjs(value).date() <= 28)
          .defined(),
        endDate: yup
          .date()
          // FIXME: このエラーを表示したいが表示できない（おそらくDayjsとreact-hook-form, yupの相性だが原因不明）
          .min(yup.ref("startDate"), t("validation.end-date") as string)
          .required(t("validation.required") as string)
          .defined(),
        name: yup
          .string()
          .max(CHARACTER_LIMIT["80"], t("validation.character.limit", { number: CHARACTER_LIMIT["80"] }) as string)
          .required(t("validation.required") as string)
          .defined(),
      })
    ),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const startDate = watch("startDate");

  // FIXME: endDateを考慮した集計を実装したらこれはいらない
  useEffect(() => {
    if (startDate) {
      setValue("endDate", dayjs(startDate).add(1, "year").subtract(1, "day").toDate());
    }
  }, [startDate, setValue]);

  useEffect(() => {
    if (done) onClose();
  }, [done, onClose]);

  const renderStartDateField = () => (
    <>
      <Box mb={1}>
        <Typography>{t("start-date")}</Typography>
      </Box>
      <Controller
        name="startDate"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl error={Boolean(fieldState.error)} fullWidth>
            {/* TODO: userテーブルの言語設定を利用 */}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
              <DatePicker
                {...field}
                disabled={edit}
                // DatePickerではDayjsを扱い、react-hook-formではDateとして扱わなければならないため、DatePicker用にDayjsに変換する
                value={dayjs(field.value)}
                format={dateUtil.DISPLAY_FORMAT}
                // 月によっては29、30、31日がない月があるため、翌月の前日（31日を選んだ場合は、翌月の30日）時点で集計するのか、
                // 月の最終日時点で集計するのは判別がつかないため、29日以降は選択させないようにする。
                shouldDisableDate={(day) => day.date() > 28}
              />
            </LocalizationProvider>
            {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );

  const renderEndDateField = () => (
    <>
      <Box mb={1}>
        <Typography>{t("end-date")}</Typography>
      </Box>
      <Controller
        name="endDate"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl error={Boolean(fieldState.error)} fullWidth>
            {/* TODO: userテーブルの言語設定を利用 */}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
              <DatePicker {...field} disabled={edit} value={dayjs(field.value)} format={dateUtil.DISPLAY_FORMAT} />
            </LocalizationProvider>
            {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );

  const renderNameField = () => (
    <>
      <Box mb={1}>
        <Typography>{t("name")}</Typography>
      </Box>
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            placeholder={t("placeholder.business-year") as string}
            fullWidth
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </>
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogHeader onClose={onClose}>{title}</DialogHeader>
        <DialogContent dividers>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid container item>
                <Grid item xs={6}>
                  {renderStartDateField()}
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={6}>
                  {renderEndDateField()}
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  {renderNameField()}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="normal" variant="contained" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button disabled={!isValid || loading} variant="contained" onClick={handleSubmit(onSubmit)} autoFocus>
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingModal open={loading} />
    </>
  );
};
